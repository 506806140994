import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import Button from '../components/Button';


import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import Layout from "../components/layout/Layout"

import LogoUni from "../svg/LogoUni";


import { Link } from 'gatsby'
import LeadForm from "../components/LeadForm"
import NewsletterSub from '../components/NewsletterSub'



export default function productTemplate({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  const slides = {

    slide1: getImage(frontmatter.featuredImage),
    slide2: '',
    slide3: '',

  }

  if(frontmatter.image1.relativePath !== 'no-foto.png'){

    slides.slide2 = getImage(frontmatter.image1)

  }

  if(frontmatter.image2.relativePath !== 'no-foto.png'){

    slides.slide3 = getImage(frontmatter.image2)

  }


  var count = 1;
  var showCarousel = false;

  const showSlides = {

    showSlide1: true,

  }

  if(slides.slide2 === ''){
    showSlides.showSlide2 = false;
  } else {
    showSlides.showSlide2 = true;
    count++;
  }

  if(slides.slide3 === ''){
    showSlides.showSlide3 = false;
  } else {
    showSlides.showSlide3 = true;
    count++;
  }

  if(count > 1)
    showCarousel = true;


  const siteVerOpts = [

    {
      label: 'Ecuador',
      value: '',


    },

    {
      label: 'Internacional',
      value: '/export/es/productos/',


    },
];

  const langOpts = [


    {
      label: 'ES',
      value: '',

    },


    {
      label: 'EN',
      value: `/export/en/products/`,

    },


  ];

const defaultNoImage = (

  <div className="absolute inset-0 bg-gradient-to-r from-cyan-500 to-blue-500 flex">
    <div className="grow"></div>
    <LogoUni className="grow-0 w-40 opacity-90 animate-[spin_1s_ease-in-out]" />
    <div className="grow"></div>
  </div>



);


  return (



    <Layout location={location} crumbLabel={frontmatter.title}
    siteVerOpts={siteVerOpts} langOpts={langOpts}
    title={frontmatter.title} desc={excerpt} banner={`${frontmatter.slug}.jpg`} pathname={frontmatter.path}
    product category={frontmatter.category} categorySlug={frontmatter.categorySlug} productSku={frontmatter.sku} productGtin13={frontmatter.gtin13} offerPrice={frontmatter.price} brandName={frontmatter.brandName} brandLogo={frontmatter.brandLogo}>


<section id="product-detail" className="text-gray-700 body-font overflow-hidden bg-white">
  <div className="container px-5 py-4 mx-auto">
    <div className="lg:w-4/5 mx-auto flex flex-wrap">
      <div className="lg:w-1/2 w-full">
        {showCarousel
          ?
              <AwesomeSlider className="aws-btn" style={{ "--slider-height-percentage": "90%",
                                                          "--organic-arrow-height": "20px",
                                                          "--control-button-opacity": "0.3",
                                                          "--control-bullet-color": "#FFFFFF",
                                                          "--control-bullet-active-color": "#FFFFFF",
                                                          "--organic-arrow-color": "#FFFFFF",
                                                          "--organic-arrow-border-radius": "2",
                                                          "--control-button-hover-opacity": "0.5",
                                                        }}>
                <div>
                    <GatsbyImage image={slides.slide1} className="h-fit" alt="Haga click para agrandar" />

                </div>
                {showSlides.showSlide2 ?
                  <div>
                      <GatsbyImage image={slides.slide2} className="h-fit" alt="Haga click para agrandar" />
                  </div>
                : <div className="w-full h-full relative">{defaultNoImage}</div> }
                {showSlides.showSlide3 ?
                  <div>
                      <GatsbyImage image={slides.slide3} className="h-fit" alt="Haga click para agrandar" />
                  </div>
                : <div className="w-full h-full relative">{defaultNoImage}</div> }
              </AwesomeSlider>
          :
            <GatsbyImage image={slides.slide1} className="h-fit" alt="Haga click para agrandar" />
          }


      </div>
      <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 className="text-sm title-font text-gray-500 tracking-widest">{frontmatter.category}</h2>
        <h1 className="text-primary-default text-3xl title-font font-medium mb-1">{frontmatter.title}</h1>
        <h3 className="text-secondary-default text-md font-medium mb-1">{frontmatter.subtitle}</h3>
        <div className="flex mb-4">

            <span className="text-gray-600 ml-3">{frontmatter.sku}</span>


        </div>
        <div className="leading-relaxed border-b-2 border-gray-200 mb-5 pb-4" dangerouslySetInnerHTML={{ __html: html }}/>

        <div className="flex">

          <Link to={'/productos/'+frontmatter.categorySlug} className="bg-secondary-lighter p-2 rounded-lg text-white hover:no-underline hover:text-white hover:bg-primary-lighter">
            Ver más productos
          </Link>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="contacto" className="mt-10">
<div className="relative bg-secondary-lighter">
  <div className="h-56 bg-secondary-lighter sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
  <StaticImage src="../images/call-center-unilimpio.jpg"
                      className=" object-cover"
                      alt="Solicite una cotización con Unilimpio"
                      placeholder="blurred"
                      layout="constrained"
                       />
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16 pt-20 ">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      <h2 className="mt-4 text-xl sm:text-4xl md:text-4xl font-bold text-white text-center ">¡SOLICITE UNA COTIZACIÓN AHORA!</h2>
      <p className="mt-6 text-2xl text-left font-normal text-white">Gracias por su interés en Unilimpio S.A. Estaremos gustosos de atenderle para brindarle mayor información acerca de nuestros productos de higiene profesional.</p>
      <div className="mt-8">
      <div className="flex flex-wrap mx-auto justify-center pt-4">
         <Link to="/contactenos/">
          <Button className=" text-primary-lighter text-semibold text-xl  rounded-xl transition duration-200 ease-in-out bg-white shadow-md hover:bg-white hover:shadow-lg transform hover:-translate-y-1 hover:scale-105 focus:outline-none" size="s" type="submit">Contáctese</Button>
         </Link>
       </div>
      </div>
    </div>
  </div>
</div>
</section>
        <NewsletterSub/>
    </Layout>

  );
}

export const pageQuery = graphql`
query ($path: String!) {
markdownRemark(frontmatter: {path: {eq: $path}}) {
  frontmatter {
    date(formatString: "DD/MM/YYYY")
    slug
    path
    featuredImage {
      childImageSharp {
        gatsbyImageData(width:800, placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
    category
    title
    subtitle
    sku
    price
    gtin13
    brandName
    brandLogo
    categorySlug
    image1 {
      childImageSharp {
        gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
      }
      relativePath
    }
    image2 {
      childImageSharp{
        gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
      }
      relativePath
    }

  }
  excerpt
  html
}
}
  `
